/* globals Typekit */

import config from '../config';

export default () => {
  if (!config.typekit.enabled) return false;

  if (typeof (Typekit) === 'undefined') {
    const loadTypekit = new Promise((res, rej) => {
      const scriptTag = document.createElement('script');
      const firstScript = document.getElementsByTagName('script')[0];
      scriptTag.src = `//use.typekit.net/${config.typekit.id}.js`;
      scriptTag.type = 'text/javascript';
      scriptTag.async = true;
      scriptTag.onload = res;
      scriptTag.onerror = rej;
      firstScript.parentNode.insertBefore(scriptTag, firstScript);
    });

    loadTypekit.then(() => {
      Typekit.load({ async: true });
    }, (e) => {
      console.error('Typekit error', e); // eslint-disable-line 
    });
  }

  return true;
};
