import React from 'react';
import PropTypes from 'prop-types';
import scrollTo from '../utils/scrollTo';

import FontAwesomeIcon from '@fortawesome/react-fontawesome';
import chevronDown from '@fortawesome/fontawesome-free-solid/faChevronDown';

const Hint = props => {
  const handleClick = (e) => {
    e.preventDefault();
    scrollTo(props.scrollTo);
  }

  return (
    <a
      className={`hint ${props.className}`}
      href={props.link}
      onClick={handleClick}
      aria-label={props.ariaLabel}
    >
      {props.icon ?
        props.icon
        :
        <FontAwesomeIcon
          className="hint__icon"
          icon={chevronDown}
        />
      }
    </a>
  );
};

Hint.defaultProps = {
  ariaLabel: 'Scroll Down',
  className: '',
  icon: null,
  scrollTo: ''
};

Hint.propTypes = {
  ariaLabel: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.node,
  link: PropTypes.string.isRequired,
  scrollTo: PropTypes.string
};

export default Hint;
