import React from 'react';

class Drinks extends React.Component {
  constructor() {
    super();

    this.state = {
      activeIndex: -1
    };
  }

  handleDrinkClick(e, index,title) {
    e.preventDefault();
    var obj_text = title;
    var type = 'drink';

    // utag.link({
    // 'event_name': 'product_interaction',
    // 'interaction_type': type,  
    // 'product_name': obj_text,
    // 'product_category': 'drinks',
    // }); 
    
    if (this.state.activeIndex !== index) {
      this.setState({ activeIndex: index });
    } else {
      this.setState({ activeIndex: -1 });
    }
  }

  render() {
    return (
      <React.Fragment>
        {
          this.props.drinks.map((drink, index) => (
            <a
              className={`fountain__drink${this.state.activeIndex === index ? ' is-active' : ''}`}
              href="#"
              key={`drink-image-${index}`}
              onClick={(e) => this.handleDrinkClick(e, index,drink.title)}
              type="button"
            >
              <img
                className="fountain__label"
                src={drink.label.src}
                alt={drink.label.alt}
              />
              <img
                className="fountain__img"
                src={drink.image.src}
                alt={drink.image.alt}
              />
              <span className="fountain__title">{drink.title}</span>
            </a>
          ))
        }
      </React.Fragment>
    )
  }
};

export default Drinks;
