import config from '../config';

// initialize Tealium on page

export default () => {
  if (!config.tealium.enabled) return false;

  if (typeof (utag) === 'undefined') {
    (() => {
      const script = document.createElement('script');
      const scripts = document.getElementsByTagName('script');
      script.src = config.tealium.urls.utag;
      script.type = 'text/javascript';
      script.async = true;
      scripts[0].parentNode.insertBefore(script, scripts[0]);
    })();
  }

  return true;
};
