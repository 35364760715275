import React from 'react';
import { Hero } from 'carbonate-ui';
import Hint from '../components/Hint';
import Layout from '../components/Layout';
import Drinks from '../components/Drinks';

import config from '../config.js';

export default (props) => {
  return (
    <Layout href={props.location.href} head={config.site.head}>
      <div className="page-content">
        <Hero
          bgDesktop={config.site.fountain.heroImage}
          bgMobile={config.site.fountain.heroImageMobile}
        >
          <Hint
            link="#"
            scrollTo=".blurb.section"
          />
        </Hero>
  
        <section className="blurb section">
          <div className="container">
            <h1 className="blurb__title">{config.site.fountain.blurb.title}</h1>
            <p className="blurb__text">{config.site.fountain.blurb.text}</p>
          </div>
        </section>
  
        <section className="products section">
          <div className="container">
            <h3 className="products__title">{config.site.fountain.products.title}</h3>
            <div className="fountain">
              <Drinks drinks={config.site.fountain.products.drinks} />
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}
