import React from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';

const Footer = (props) => {
  const links = props.links.map(link => (
    <li className="footer__list-item" key={`footer-link-${shortid.generate()}`}>
      <a className="footer__link"  href={link.url}  onClick={link.onClick}  target="_blank">
        {link.title}
      </a>
    </li>
  ));

  const logo = props.logo ? props.logo : null;

  return (
    <footer className={`footer ${props.className}`}>
      {logo}
      <ul className="footer__list">
        {links}
      </ul>
      <p className="footer__text">{props.copyright}</p>
    </footer>
  );
};

Footer.defaultProps = {
  className: '',
  copyright: `DR PEPPER is a registered trademark of Dr Pepper/Seven Up, Inc.
    &copy;2018 Dr Pepper/Seven Up, Inc.
  `,
  links: [
    { title: 'Privacy Policy', url: '/' },  
    { title: 'Do Not Share Or Sell My Personal Information', url: '/' },
    { title: 'CA Privacy Policy', url: '/' },
    { title: 'Terms of Use', url: '/' },
    { title: 'Accessibility', url: '/' },
    { title: 'Contact Us', url: '/' },
    { title: 'Careers', url: '/' },
    { title: 'Let\'s Play', url: '/' },
    { title: 'Corporate Information', url: '/' },
    { title: 'FAQ', url: '/' },
  ],
  logo: null,
};

Footer.propTypes = {
  className: PropTypes.string,
  copyright: PropTypes.string,
  logo: PropTypes.node,
  links: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    url: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
  })),
};

export default Footer;
