function scrollTo(el, offset = -2, duration = 500) {
  if (typeof window !== 'undefined') {
    const $ = require('jquery');
    const $body = $('html, body');

    return $body.animate({
      scrollTop: (el ? $(el).offset().top : 0) - offset
    }, duration);
  }
}

export default scrollTo;
