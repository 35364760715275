import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

import config from '../config';

const Head = props => (
  <Helmet>
    <title>{`${props.metaTitle}`}</title>
    <meta name="title" content={props.metaTitle} />
    <meta name="description" content={props.metaDescription} />

    <meta property="og:title" content={props.ogTitle} />
    <meta property="og:description" content={props.ogDescription} />
    <meta property="og:site_name" content={props.ogSiteName} />
    <meta property="og:url" content={props.ogURL} />

    <link rel="shortcut icon" href={props.favicon} />
  </Helmet>
);

Head.defaultProps = {
  favicon: '',
  ogDescription: '',
  ogTitle: '',
  ogSiteName: '',
  ogURL: '',
  metaDescription: 'Description',
  metaTitle: 'Title',
};

Head.propTypes = {
  favicon: PropTypes.string,
  ogDescription: PropTypes.string,
  ogTitle: PropTypes.string,
  ogSiteName: PropTypes.string,
  ogURL: PropTypes.string,
  metaDescription: PropTypes.string,
  metaTitle: PropTypes.string,
};

export default Head;
