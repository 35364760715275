import React from 'react';
import PropTypes from 'prop-types';

import { Logo } from 'carbonate-ui';
import Nav from '../components/Nav';

class Header extends React.Component {
  constructor() {
    super();

    this.state = { expanded: false };
    this.toggleNav = this.toggleNav.bind(this);
    this.closeNav = this.closeNav.bind(this);
  }

  toggleNav() {
    if (!this.state.expanded) {
      this.setState({ expanded: true });
      this.initCloseEvents();
      document.body.classList.add('nav-expanded');
    } else {
      this.closeNav();
    }
  }

  closeNav() {
    this.setState({ expanded: false });
    this.removeCloseEvents();
    document.body.classList.remove('nav-expanded');
  }

  initCloseEvents() {
    window.addEventListener('scroll', this.closeNav);
    window.addEventListener('resize', this.closeNav);
  }

  removeCloseEvents() {
    window.removeEventListener('scroll', this.closeNav);
    window.removeEventListener('resize', this.closeNav);
  }

  render() {
    let logo;

    if (this.props.logo) {
      logo = (
        <Logo
          className="header__logo"
          src={this.props.logo}
          onLogoClick={this.props.onLogoClick}
        />
      );
    }

    let headerElements;

    if (this.props.children) {
      headerElements = (
        <div>
          {this.props.children}
        </div>
      );
    } else {
      headerElements = (
        <div>
          {logo}
          <button
            className="nav-toggle"
            onClick={this.toggleNav}
            type="button"
            aria-expanded={this.state.expanded}
            aria-label="Toggle navigation"
          >
            <span className="nav-toggle__bar"></span>
            <span className="nav-toggle__bar"></span>
            <span className="nav-toggle__bar"></span>
          </button>
          <Nav
            links={this.props.links}
            expanded={this.state.expanded}
          />
        </div>
      );
    }

    return (
      <header className={`header ${this.props.className}${this.state.expanded ? ' is-active' : ''}`}>
        {headerElements}
        <div
          className="header__overlay"
          onClick={this.closeNav}
        ></div>
      </header>
    );
  }
}

Header.defaultProps = {
  className: '',
  children: null,
  logo: '',
  links: [
    { title: 'Products', url: '/products' },
    { title: 'Recipes', url: '/recipes' },
  ],
  onLogoClick: () => false,
};

Header.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOf([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  logo: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.shape({
    className: PropTypes.string,
    image: PropTypes.string,
    onClick: PropTypes.func,
    title: PropTypes.string.isRequired,
    url: PropTypes.string,
  })),
  onLogoClick: PropTypes.func,
};

export default Header;
